@import '@carbon/colors/scss/colors';

$image-width: 3.5rem;

.ContactOption {
  &:not(:last-child) {
    padding: 0 0 3rem 0;
  }
  &__title {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  &__image {
    display: flex;
    align-items: center;
    width: 2.5rem;
  }
}