@import '@carbon/colors/scss/colors';

.SkillsPage {
  &__section {
    &:not(:last-child)  {
      padding-bottom: 4rem;
    }
  }
  &__title {
    margin-bottom: 1rem;
  }
  &__skills {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &__skill {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 14rem;
    height: 12rem;
    padding: 1rem;
    margin: 0.5rem 0.5rem 0 0;
    background-color: white;
    &.bx--link:hover {
      outline: 1px solid $ibm-color__blue-60;
      outline-offset: 1px;
    }
  }
  &__image {
    max-width: 10rem;
    margin: 1rem 0 1.5rem 0;
    font-size: 0;
  }
  &__name {
    font-size: 1.125rem;
    line-height: 1.25rem;
  }
}
