@import '~carbon-components/scss/globals/scss/styles.scss';
@import '@carbon/colors/scss/colors';
@import './CarbonOverrides.scss';

html {
  background-color: $ui-01;
  min-width: 850px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#main-content {
  background-color: $ui-01;
  padding: 0;
}
