@import '@carbon/colors/scss/colors';

.ContactsPage {
  display: flex;
  flex-direction: column;
  &__info-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.25rem 0 1.25rem;
    height: 3rem;
    width: 24rem;
    font-size: 1.5rem;
    background-color: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    .bx--copy-btn {
      margin-left: 1.25rem;
    }
  }
  &__socials {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
  }
  &__social-tile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 14rem;
    height: 14rem;
    padding: 1rem;
    margin: 0 0.5rem 0.5rem 0;
    background-color: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    &:hover {
      outline: 1px solid $ibm-color__blue-60;
      outline-offset: 1px;
    }
    svg {
      width: 4rem;
      height: 4rem;
    }
  }
  &__social-handle {
    font-size: 1.25rem;
    margin-top: 1rem;
  }
}
