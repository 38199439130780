.bx--modal-footer .bx--btn {
  font-size: 1.25rem;
}

.bx--modal-header__heading {
  font-size: 1.5rem;
}

.bx--data-table-header {
  padding: 0 0 0.5rem 0;
  &__title {
    font-size: 1.5rem;
  }
  &__description {
    font-size: 1rem;
  }
}

.bx--data-table td {
  background: white;
}

.bx--inline-notification {
  max-width: unset;
}