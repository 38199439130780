@import '@carbon/colors/scss/colors';

.PdfViewToolbar {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 3rem;
  z-index: 1000;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: $ibm-color__gray-50;
  &__wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__pagination-count {
    padding-left: 1.5rem;
    color: white;
    font-size: 1rem;
  }
  &__controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__button.bx--link {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    transition: 200ms;
    font-weight: 500;;
    color: white;
    text-decoration: none;
    &:hover {
      background-color: $ibm-color__blue-60;
    }
    &:hover,
    &:link,
    &:visited {
      color: white !important;
    }
    svg {
      margin-right: 0.75rem;
    }
  }
}