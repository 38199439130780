@import '@carbon/colors/scss/colors';

.HomePage {
  &__info {
    padding: 2rem 2.5rem 2.5rem 2.5rem;
    margin-bottom: 3rem;
    background-color: white;
    border-left: 6px solid $ibm-color__blue-50;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  }
  &__name {
    font-size: 4rem;
    font-weight: 700;
    letter-spacing: 0.125rem;
  }
  &__jobs {
    margin: 1rem 0 0.5rem 0;
  }
  &__title {
    font-size: 2rem;
    padding-bottom: 0.5rem;
  }
  &__statement {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  &__paragraph {
    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }
    a {
      text-decoration: none;
    }
  }
  .react-photo-gallery--gallery {
    img {
      background-color: $ibm-color__gray-20;
      &:hover {
        outline: 2px solid $ibm-color__blue-60;
      }
    }
  }
}
.react-images__blanket {
  z-index: 9998 !important;
}
.react-images__positioner {
  z-index: 9999 !important;
}
