@import '@carbon/colors/scss/colors';

.PdfView {
  background-color: $ibm-color__gray-20;
  .react-pdf {
    &__Document {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      height: 100%;
      padding: 2rem 0;
    }
    &__Page__canvas {
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
    }
    &__message--error {
      width: 100%;
      padding: 0 2rem;
    }
  }
}