@import '@carbon/colors/scss/colors';

.EducationPage {
  &__universities {
    display: flex;
    margin-bottom: 3rem;
    @media screen and (max-width: 1450px) {
      flex-direction: column;
    }
  }
  &__uni-spacer {
    width: 1rem;
    height: 1rem;
  }
  &__uni-info {
    flex: 1;
    padding: 1rem 2rem;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }
  &__uni-row {
    line-height: 1.5rem;
    font-size: 1.125rem;
    .bx--link {
      font-size: 1.125rem;    
      line-height: 1.5rem;
    }
  }
  &__uni-logo {
    margin-bottom: 1.5rem;
    cursor: pointer;
    &:hover, &:focus {
      outline: 1px solid $ibm-color__blue-60;
      outline-offset: 0.5rem;
    }
    img {
      height: 5rem;
    }
  }
  &__gpa {
    font-weight: bold;
    margin-left: 1rem;
  }
  &__grade {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    font-weight: 700;
    color: white;
    &--A {
      background-color: $ibm-color__green-40;
    }
    &--B {
      background-color: $ibm-color__blue-50;
    }
    &--P {
      background-color: $ibm-color__cool-gray-60;
    }
  }
  &__extended-course-info {
    padding: 1rem 0;
  }
  &__row {
    &:not(:last-child) {
      padding-bottom: 0.5rem;
    }
  }
  &__row-links {
    list-style: disc;
    margin-left: 1rem;
  }
  &__quick-detail {
    display: inline-block;
    width: 14rem;
  }
}
