@import '@carbon/colors/scss/colors';

.App {
  &__header {
    .bx--header {
      &__name {
        font-size: 1.25rem;
      }
      &__menu-item {
        font-size: 15px;
        letter-spacing: 1px;
        color: white;
      }
    }
    .bx--side-nav a.bx--header__menu-item {
      height: 2.5rem;
    }
    .bx--switcher__item {
      height: 3rem;
      &-link {
        height: 3rem;
        display: flex;
        align-items: center;
        color: $ibm-color__gray-20;
        svg {
          margin-right: 1rem;
          color: $ibm-color__gray-20;
        }
      }
    }
  }
  &__profile-image {
    width: 2rem;
    height: 2rem;
    margin-left: 1rem;
  }
  &__side-link--active .bx--header__menu-item {
    border-left: 2px solid $ibm-color__blue-60;
  }
  &--mobile {
    .bx--side-nav__overlay-active {
      width: 100%;
      height: 100%;
    }
  }
}
